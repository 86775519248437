<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Device groups
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <portal to="secondNavbarRight">
      <b-button
        v-if="validateIfHasPermission([
          permissions.MODIFY_DEVICE_GROUPS.value.dbValue
        ])"
        class="button is-primary force-right"
        @click="$router.push({ name: 'creategroup' })"
      >
        New device group
      </b-button>
    </portal>
    <card
      external-card="material-card-content"
    >
      <div
        slot="body"
      >
        <div>
          <p class="has-margin-top">
            Create groups of similar devices and configure their settings here.
          </p>
          <br>
          <b-input
            v-if="sortedGroups.length > 1"
            v-model="search"
            v-focus
            name="search"
            icon="magnify"
            type="search"
            placeholder="Search groups..."
            style="width: -webkit-fill-available;"
            autocomplete="off"
            @input="searching"
          />
          <br>
          <div
            id="app"
          >
            <div class="columns is-multiline">
              <div
                v-for="group in filteredData"
                :key="group.id"
                :class="sortedGroups.length > 1 ? 'column is-half' : 'column is-full'"
              >
                <div class="box">
                  <header>
                    <p class="title is-4">
                      {{ group.profile }}
                    </p>
                  </header>
                  <br>
                  <main class="subtitle">
                    <span>{{ group.associatedDevicesTotal }}</span>
                    <span>{{ group.associatedDevicesTotal === 1 ? ' device' : ' devices' }}</span>
                  </main>
                  <footer class="force-right">
                    <b-button
                      type="is-light"
                      icon-right="pencil"
                      class="mr-2"
                      @click="$router.push(`/${$route.params.companyId}/group/${group.id}`)"
                    >
                      Edit
                    </b-button>
                    <b-button
                      type="is-light"
                      icon-right="clipboard-text-multiple-outline"
                      class="mr-2"
                      @click="$router.push(
                        `/${$route.params.companyId}/group/${group.id}/${'duplicate'}`
                      )"
                    >
                      Duplicate
                    </b-button>
                    <b-tooltip
                      type="is-dark"
                      position="is-bottom"
                      :active="group.isDefaultProfile"
                      label="You cannot delete the default device group"
                    >
                      <b-button
                        :disabled="group.isDefaultProfile"
                        type="is-light"
                        icon-right="trash-can"
                        @click="openConfirmationModal(group)"
                      >
                        Delete
                      </b-button>
                    </b-tooltip>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import { mapActions, mapGetters } from 'vuex';
import card from '@/components/cross/Card.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import snackBarMessage from '@/helpers/snackBarMessage';
import ErrorMixin from '@/mixins/error';
import vtulEnums from '../../../cross/index';
import profileSettings from '../config/profileSettings';
import CompanyMixin from '../mixins/company';
import RoleMixin from '../mixins/roles';

const { permissions } = vtulEnums.enum.roles;

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  maxPatternLength: 0,
  minMatchCharLength: 1,
  keys: ['profile'],
};
export default {
  name: 'EmployeesProfile',
  metaInfo: {
    title: 'Device groups',
  },
  components: {
    card,
  },
  mixins: [ErrorMixin, CompanyMixin, RoleMixin],
  data() {
    return {
      permissions,
      limitEmployeeProfiles: 10,
      skipEmployeeProfiles: 0,
      total: 0,
      filteredData: [],
      search: '',
      fuse: null,
      currentPage: this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1,
    };
  },
  computed: {
    ...mapGetters('employee-profiles', { findEmployeeProfileInStore: 'find' }),
    groups() {
      const profiles = this.findEmployeeProfileInStore({
        query: {
          companyId: this.currentCompany.id,
        },
      }).data;
      const settings = [];
      const allSettings = profileSettings;
      allSettings.enums.forEach((setting) => {
        if ((this.currentCompany.advancedPlan || !setting.value.isAdvancedPlan)
          && setting.value.available
          && this.validateIfHasCapability(setting.value.requestedCapabilities)) {
          settings.push(setting.value.id);
        }
      });
      const data = [];
      if (profiles) {
        profiles.forEach((profile) => {
          const enabledSettings = [];
          settings.forEach((setting) => {
            if (profile[setting] && profile[setting].enabled) enabledSettings.push(setting);
          });
          data.push({
            id: profile.id,
            profile: profile.name,
            isDefaultProfile: profile.isDefaultProfile,
            associatedDevicesTotal: profile.associatedDevicesTotal,
          });
        });
      }
      return data;
    },
    sortedGroups() {
      if (this.groups.length === 0) return [];
      const dataSorted = this.groups;
      dataSorted.sort((x, y) => {
        if (x.profile > y.profile) {
          return 1;
        }
        if (x.profile < y.profile) {
          return -1;
        }

        return 0;
      });
      dataSorted.sort((x, y) => {
        if (x.isDefaultProfile) {
          return -1;
        }
        if (y.isDefaultProfile) {
          return 1;
        }
        return 0;
      });
      this.updateFuseData(dataSorted);
      return dataSorted;
    },

  },
  watch: {
    sortedGroups() {
      this.searching();
    },
    currentPage(newPage) {
      this.$router.replace(`/${this.$route.params.companyId}/groups/${newPage}`);
    },
  },
  created() {
    this.searching();
    this.fetchEmployeeProfilesFromApi();
  },
  methods: {
    ...mapActions('employee-profiles', { findEmployeeProfile: 'find', removeProfile: 'remove' }),
    ...mapActions('devices', { findDevices: 'find' }),
    ...mapActions('emit-to-agent', { emitToAgent: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    searching() {
      const input = this.search;
      if (input && this.fuse) {
        this.filteredData = this.fuse.search(input);
      } else {
        this.filteredData = this.sortedGroups;
      }
    },
    updateFuseData(data) {
      this.fuse = new Fuse(data, fuseOptions);
    },
    async fetchEmployeeProfilesFromApi(skip) {
      if (!this.currentCompany) return;
      let skipDevices = skip;
      if (!skipDevices) skipDevices = 0;

      const { total, data } = await this.findEmployeeProfile({
        query: {
          $client: { getTotalDevices: true },
          $skip: skipDevices,
          $limit: 50,
          companyId: this.currentCompany.id,
        },
      });
      this.total = total;
      skipDevices += data.length;
      if (skipDevices < total) {
        await this.fetchEmployeeProfilesFromApi(skipDevices);
      } else {
        this.loading = true;
      }
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipEmployeeProfiles = (this.currentPage - 1) * this.limitEmployeeProfiles;
    },
    openConfirmationModal(profile) {
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          title: 'Delete device group',
          message: `Are you sure you want to delete the ${profile.profile} group?`,
          confirmText: 'Delete',
          onConfirm: () => this.deleteEmployeeProfile(profile),
        },
      });
    },
    async deleteEmployeeProfile(profile) {
      try {
        await this.removeProfile([profile.id, {
          query: {
            companyId: this.currentCompany.id,
          },
        }]);
        const user = await this.$store.getters['auth/user'];
        const log = {
          user: user.email,
          action: `Device group "${profile.profile}" deleted.`,
          companyId: this.currentCompany.id,
        };
        await this.createAdminLog(log);
        this.fetchEmployeeProfilesFromApi();
      } catch (error) {
        snackBarMessage.showError(error);
      }
    },
  },
};
</script>
<style scope>
  .has-margin-top{
    margin-top:0.7rem;
  }
</style>
